import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SIPrefix } from '../../../../../shared/models/SIPrefix';
import { of } from 'rxjs';
import { EmuInputComponent } from '../../../../../shared/components/inputs/emu-input/emu-input.component';
import { EmuListInputComponent } from '../../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { SelectingValueDataComponent } from '../../shared/selecting-value-data/selecting-value-data.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { getDefinedRanges } from '../../../../../shared/components/graph-uplot/graph/utils/getDefinedRanges';
import { getValueModes, ValueDisplayMode } from '../ValueDisplayMode.model';
import { DashboardItemTypeValueDisplay } from '../../../models/DashboardItemTypeValueDisplay';

@Component({
  selector: 'app-dashboard-value-display-settings-input',
  standalone: true,
  imports: [
    CommonModule,
    EmuInputComponent,
    EmuListInputComponent,
    FormsModule,
    MatCheckboxModule,
    MatSliderModule,
    SelectingValueDataComponent,
    TranslateModule,
  ],
  templateUrl: './dashboard-value-display-settings-input.component.html',
  styleUrl: './dashboard-value-display-settings-input.component.scss',
})
export class DashboardValueDisplaySettingsInputComponent {
  ranges = getDefinedRanges(false);

  modes = getValueModes();

  public unitPrefixes = SIPrefix;

  @Input() data: DashboardItemTypeValueDisplay;

  constructor(
    protected cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  get anyData(): any {
    return this.data;
  }

  formatLabel(value: number): string {
    return `${value}`;
  }

  public getRangeString(scale: string): string {
    return this.translate.instant(`UPLOT.RANGE.${scale.toUpperCase()}`);
    // return this.translate.instant(
    //   `UPLOT.RANGE.${DataDisplayDefaultRanges[Number(scale)].toUpperCase()}`,
    // );
  }

  public getModeString(scale: number): string {
    return this.translate.instant(
      `DASHBOARD.VALUE_MODE.${ValueDisplayMode[Number(scale)].toUpperCase()}`,
    );
  }

  public displayFnPrefix(unit: any): string {
    return `${unit ?? ''}${this.data.unit}`;
  }

  public transformValue(unit: any) {
    return unit.prefix;
  }

  protected readonly of = of;
}
