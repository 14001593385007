import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocaleSessionService } from '../../services/localeSession.service';
import { DateDisplayService, DateFormat } from './date-display.service';
import { BehaviorSubject, debounceTime, filter, map, merge } from 'rxjs';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-date-display',
  standalone: true,
  imports: [CommonModule, MatTooltip],
  templateUrl: './date-display.component.html',
  styleUrl: './date-display.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateDisplayComponent {
  constructor(
    public locale: LocaleSessionService,
    private dateDisplayService: DateDisplayService,
    private cd: ChangeDetectorRef,
  ) {}

  private _value: Date = null;

  @Input() displayTime: boolean = true;

  @Input() set value(value: Date | number | string) {
    if (value == null) {
      this._value = null;
      return;
    }
    let date = new Date(value);
    if (isNaN(date.getTime())) date = null;
    this._value = date;
    this.cd.detectChanges();
  }

  @Input() disableFormatChange: boolean = false;

  get utcDateString() {
    if (this.value == null) return null;
    return this.value.toLocaleString(this.locale.locale, { timeZone: 'UTC' }) + ' (UTC)';
  }

  private _format = new BehaviorSubject<DateFormat>(null);

  format$ = merge(this.dateDisplayService.format$, this._format).pipe(
    filter((o) => !!o),
    debounceTime(50),
  );

  dateString = this.format$.pipe(
    map((format) => {
      return new Intl.DateTimeFormat(this.locale.locale, {
        dateStyle: format,
        ...(this.displayTime && { timeStyle: format }),
        timeZone: this.locale.timezone,
      }).format(this.value);
    }),
  );

  get value(): Date {
    return this._value;
  }

  @Input() set format(val: DateFormat) {
    this._format.next(val);
  }

  formatChange() {
    if (this._format.value || this.disableFormatChange) return;
    this.dateDisplayService.formatChange();
  }
}
