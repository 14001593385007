import { Component } from '@angular/core';
import { BaseWidgetComponent } from '../base-widget.component';
import { DashboardItemDataText } from '../../models/DashboardItemDataText.model';
import { FitTextDirective } from '../../../../shared/directives/fit-text.directive';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-dashboard-text',
  templateUrl: './dashboard-text.component.html',
  styleUrls: ['./dashboard-text.component.scss'],
  standalone: true,
  imports: [NgIf, FitTextDirective],
})
export class DashboardTextComponent extends BaseWidgetComponent {
  override get data(): DashboardItemDataText {
    return this.item.data?.text;
  }

  get justifyContent(): string {
    switch (this.data.align) {
      case 'justify':
      case 'center':
        return 'center';
      case 'right':
        return 'flex-end';
      case 'left':
        return 'flex-start';
    }
  }

  get link() {
    if (this.data?.url?.length > 0) {
      if (this.data.url.includes('://')) return this.data.url;
      else return '//' + this.data.url;
    } else return null;
  }
}
