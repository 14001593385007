import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  isDevMode,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UplotInputData } from '../graph/models/UplotInputData.model';
import {
  catchError,
  debounceTime,
  filter,
  finalize,
  map,
  Observable,
  of,
  skip,
  Subject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { DataRequestDto } from '../../../../../api-main';
import { UplotSeriesConfig } from '../graph/models/UplotSeriesConfig.model';
import { UplotData } from '../graph/models/UplotData.model';
import { formatDataToScale } from '../graph/utils/formatDataToScale';
import { UplotResponseData } from '../graph/models/UplotResponseData.model';
import { uplotValidateData } from '../graph/utils/uplotValidateData';
import { uplotFormatDataTimezoneToLocal } from '../graph/utils/uplotFormatTimezoneToLocal';
import { UplotRange } from '../graph/models/UplotRange.model';
import { uplotSetNewSeries } from '../graph/config/uplotSeriesConfiguration';
import { UplotMakeOpts } from '../graph/uplotMakeUplot';
import { GraphButtonsComponent } from '../graph/graph-buttons/graph-buttons.component';
import { DatePipe, NgForOf, NgIf } from '@angular/common';
import { RangePickerComponent } from '../range-picker/range-picker.component';
import { FormatValuePipe } from '../../../pipes/format-value.pipe';
import { TranslateService } from '@ngx-translate/core';
import { LocaleSessionService } from '../../../services/localeSession.service';
import { uplotCreateLoadingCircle } from '../graph/utils/uplotLoadingCircle';
import { uplotMakeHeatmapHourly } from './hourly/uplotMakeHeatmapHourly';
import { setTranslationServiceForScrollMessage } from '../graph/utils/uplotShowMessage';
import { UplotOnRescaleData, uplotRescalePlugin } from '../graph/plugins/uplotPluginRescale';
import { MatSlider, MatSliderThumb } from '@angular/material/slider';
import { HeatmapLegendComponent } from './heatmap-legend/heatmap-legend.component';

@Component({
  selector: 'app-heat-map',
  standalone: true,
  imports: [
    GraphButtonsComponent,
    NgIf,
    RangePickerComponent,
    NgForOf,
    FormatValuePipe,
    MatSlider,
    MatSliderThumb,
    HeatmapLegendComponent,
  ],
  templateUrl: './heat-map.component.html',
  styleUrl: './heat-map.component.scss',
  providers: [FormatValuePipe, DatePipe],
})
export class HeatMapComponent implements AfterViewInit, OnDestroy {
  @ViewChild('uplotContainer')
  private container: ElementRef;

  uplot: any;

  protected unsubscribe = new Subject<void>();

  private dataRequested$ = new Subject<void>();

  heatmapPaletteColors = ['#1d4877', '#1b8a5a', '#fbb021', '#f68838', '#ee3e32'];
  // heatmapPaletteColors = ['#EBF2FA', '#D3E1F8', '#BDD2F4', '#A6C3F2', '#4681E0'];

  loaded: boolean = false;

  @Input({ required: true }) data: UplotInputData;

  @Input({ required: true }) displayedRange: UplotRange;

  @Input({ required: true }) seriesConfig$: Subject<UplotSeriesConfig[]>;

  @Input() uniqueId: string = 'defaultUplotGraph';

  @Input() refresh$: Subject<void> = new Subject<void>();

  @Output() rangeChanged = new EventEmitter<UplotRange>();

  @Output() graphLoaded = new EventEmitter<void>();

  protected preventRefreshingOnRescale = true;

  protected loadedRange: UplotRange;

  private seriesConfig: UplotSeriesConfig[];

  protected displayedScale: DataRequestDto.ScaleEnum = DataRequestDto.ScaleEnum.HOURLY;

  protected fetchScale: DataRequestDto.ScaleEnum = DataRequestDto.ScaleEnum.FIFTEENMINUTES;

  constructor(
    private translateService: TranslateService,
    private formatValuePipe: FormatValuePipe,
    private datePipe: DatePipe,
    private localeSessionsService: LocaleSessionService,
  ) {
    setTranslationServiceForScrollMessage(translateService);
  }

  get breakpoints(): Array<[]> {
    return this.uplot?.heatmap?.breakpoints ?? [];
  }

  // debugGetScale() {
  //   const scale = this.uplot?.scales?.y;
  //   if (!scale) return null;
  //   return `${scale.min} - ${scale.max}`;
  // }

  ngAfterViewInit(): void {
    // this.setupRange();
    if (!this.data) throw new Error('data is undefined!');

    const loadingEl = uplotCreateLoadingCircle(this.container.nativeElement);
    loadingEl.show();
    this.getData().subscribe((data) => {
      this.prepareChart(data);

      this.uplot.seriesVisibiltyChanged$ = new Subject<void>();

      // console.log('uplot:', this.uplot);
      loadingEl.destroy();
      this.setScaleAndAllowRefreshOnScale();
      this.loaded = true;
    });

    this.seriesConfig$.pipe(takeUntil(this.unsubscribe)).subscribe((data: UplotSeriesConfig[]) => {
      this.setSeries(data);
    });

    this.refresh$.pipe(debounceTime(100), takeUntil(this.unsubscribe)).subscribe(() => {
      this.refreshData();
    });
  }

  private refreshData() {
    this.preventRefreshingOnRescale = true;
    const u = this.uplot;
    if (u == undefined) return timer(100).subscribe(() => this.refreshData());
    u.loadingElement.show();
    this.getData()
      .pipe(
        take(1),
        finalize(() => {
          u.redraw();
          u.loadingElement.hide();
          this.setScaleAndAllowRefreshOnScale();
        }),
      )
      .subscribe((data: UplotData) => {
        // console.log('=>(heat-map.component.ts:134) data', data);
        if (!data) return;
        u.setData(data);
      });
  }

  protected setScaleAndAllowRefreshOnScale() {
    const offset = 3600 * 24;
    const min = this.displayedRange.min - offset;
    const max = this.displayedRange.max;
    setTimeout(() => {
      this.uplot.setScale('x', { min, max });

      setTimeout(() => (this.preventRefreshingOnRescale = false), 100);
    });
  }

  protected getData(): Observable<UplotData> {
    this.dataRequested$.next();

    const responseData: Observable<UplotResponseData> = this.data(
      this.fetchScale,
      // this.standardizeRange(this.displayedRange),
      this.displayedRange,
    );
    return <Observable<UplotData>>responseData.pipe(
      take(1),
      tap((response: UplotResponseData) => {
        uplotValidateData(response);
        this.setSeries(response.series);
      }),
      map((response: UplotResponseData) => {
        const timezoneFormatted = uplotFormatDataTimezoneToLocal(response.data);
        this.setLoadedRange();

        const data = formatDataToScale({
          data: timezoneFormatted,
          scale: this.displayedScale,
          series: response.series,
        });
        // console.log('=>(heat-map.component.ts:147) data', data);
        setTimeout(() => this.uplot.seriesVisibiltyChanged$.next(), 100);
        return data;
      }),
      map((data: UplotData) => {
        return this.handleDST(data);
      }),
      tap(() => this.graphLoaded.emit()),

      catchError((err) => {
        console.log(err);
        if (isDevMode()) console.warn('Failed to download graph data: ', err);
        return of(null);
        // throw new Error('Failed to download graph data: ' + err);
      }),
      takeUntil(this.dataRequested$),
      takeUntil(this.unsubscribe),
    );
  }

  private handleDST(data: UplotData): UplotData {
    //data is already processed to be in hourly scale
    const xData = data[0];

    const dates = xData.map((o) => new Date(o * 1000));

    let firstDateIndex: number;
    let nextDateIndex: number;

    for (let i = 0; i < dates.length; i++) {
      const date = dates[i as number];
      let foundIndex = dates.findIndex(
        (searchDate, dateI) =>
          i != dateI &&
          date.getDate() == searchDate.getDate() &&
          date.getMonth() == searchDate.getMonth() &&
          date.getFullYear() == searchDate.getFullYear() &&
          date.getHours() == searchDate.getHours(),
      );

      if (foundIndex > 0) {
        firstDateIndex = i;
        nextDateIndex = foundIndex;
        break;
      }
    }

    if (firstDateIndex >= 0 && nextDateIndex >= 0) {
      data[0].splice(nextDateIndex, 1);
      data.slice(1).forEach((seriesData) => {
        seriesData[firstDateIndex as number] =
          seriesData[firstDateIndex as number] + seriesData[nextDateIndex as number];
        seriesData.splice(nextDateIndex, 1);
      });
    }

    return data;
  }

  private setSeries(seriesConfig: UplotSeriesConfig[]) {
    this.seriesConfig = seriesConfig;
    if (this.uplot) {
      uplotSetNewSeries(this.uplot, seriesConfig);
      this.uplot.redraw();
    }
  }

  private prepareChart(data: UplotData) {
    const opts: UplotMakeOpts = {
      data,
      container: this.container.nativeElement,
      title: 'Graph',
      seriesConfig: this.seriesConfig,
      plugins: [this.setDataOnRescale()],
      valueFormatFunction: this.formatValuePipe.transform,
      dateFormatFunction: (val) =>
        this.datePipe.transform(
          val,
          'shortDate',
          this.localeSessionsService.timezone,
          this.localeSessionsService.locale,
        ),
      localeService: this.localeSessionsService,
      translateService: this.translateService,
    };
    const u = this.makeChart(opts);
    u.container = this.container.nativeElement;
    u.uniqueId = this.uniqueId;
    u._userConfig = {
      showTooltip: true,
      fillSeries: true,
    };
    this.uplot = u;
  }

  protected makeChart(param: UplotMakeOpts) {
    return uplotMakeHeatmapHourly(param, this.heatmapPaletteColors);
  }

  protected setDataOnRescale() {
    const onRescale: UplotOnRescaleData = new Subject();
    const cancelLastRequest = new Subject<void>();
    onRescale
      .pipe(
        filter(() => this.preventRefreshingOnRescale == false),
        skip(1), //skip first event after setting graph - consider starting listening only after graph is fully loaded
        debounceTime(400),
        // skipWhile(() => this.preventRefreshing || this.preventRefreshingOnRescale),
        takeUntil(this.unsubscribe),
      )
      .subscribe((rescale) => {
        const selectedRange = rescale.scalesX;

        this.displayedRange = this.standardizeRange(selectedRange);
        // this.displayedRange = selectedRange;

        this.rangeChanged.emit(this.displayedRange);

        const getNewData = !this.isDataWithinTheScale();

        // this.saveCache();

        if (!getNewData) {
          cancelLastRequest.next();
          rescale.data.next(null);
          return;
        }

        this.setLoadedRange();
        cancelLastRequest.next();
        rescale.data.next(this.getData());
      });

    return uplotRescalePlugin(onRescale, cancelLastRequest, this.unsubscribe);
  }

  standardizeRange(range: UplotRange) {
    const minDate = new Date(range.min * 1000);
    minDate.setUTCHours(-this.localeSessionsService.getTimezoneOffsetInHours(minDate), 0, -1, 0);
    range.min = minDate.getTime() / 1000;

    const maxDate = new Date(range.max * 1000);
    maxDate.setUTCHours(
      24 - this.localeSessionsService.getTimezoneOffsetInHours(maxDate),
      0,
      -1,
      0,
    );

    range.max = maxDate.getTime() / 1000;
    return { min: range.min, max: range.max };
  }

  protected isDataWithinTheScale(): boolean {
    if (!this.loadedRange || !this.displayedRange) return true;
    const maxDifference = (this.displayedRange.max - this.displayedRange.min) / 10; //3;

    return (
      this.loadedRange.min < this.displayedRange.min + maxDifference &&
      this.loadedRange.max > this.displayedRange.max - maxDifference
    );
  }

  protected setLoadedRange(): void {
    if (!this.loadedRange) this.loadedRange = <any>{};
    this.loadedRange.min = this.displayedRange.min;
    this.loadedRange.max = this.displayedRange.max;
  }

  ngOnDestroy(): void {
    if (this.uplot) this.uplot.destroy();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
