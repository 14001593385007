type TouchPoint = { x: number; y: number; dx: number; dy: number; d?: number };
export function touchZoomPlugin() {
  let u;
  let over;
  let rect, oxRange, xVal;
  let fr: TouchPoint = { x: 0, y: 0, dx: 0, dy: 0 };
  let to: TouchPoint = { x: 0, y: 0, dx: 0, dy: 0 };
  let rafPending;
  function init(_u) {
    u = _u;
    over = u.over;

    rafPending = false;

    over.addEventListener('touchstart', startTouch);

    over.addEventListener('touchend', endTouch);
  }

  function storePos(t: TouchPoint, e) {
    let ts = e.touches;

    let t0 = ts[0];
    let t0x = t0.clientX - rect.left;
    let t0y = t0.clientY - rect.top;

    if (ts.length == 1) {
      t.x = t0x;
      t.y = t0y;
      t.d = t.dx = t.dy = 1;
    } else {
      let t1 = e.touches[1];
      let t1x = t1.clientX - rect.left;
      let t1y = t1.clientY - rect.top;

      let xMin = Math.min(t0x, t1x);
      let yMin = Math.min(t0y, t1y);
      let xMax = Math.max(t0x, t1x);
      let yMax = Math.max(t0y, t1y);

      // midpts
      t.y = (yMin + yMax) / 2;
      t.x = (xMin + xMax) / 2;

      t.dx = xMax - xMin;
      t.dy = yMax - yMin;

      // dist
      t.d = Math.sqrt(t.dx * t.dx + t.dy * t.dy);
    }
  }

  function zoom() {
    rafPending = false;

    let left = to.x;

    let xFactor = fr.dx / to.dx;

    let leftPct = left / rect.width;

    let nxRange = oxRange * xFactor;
    let nxMin = xVal - leftPct * nxRange;
    let nxMax = nxMin + nxRange;

    u.batch(() => {
      u.setScale('x', {
        min: nxMin,
        max: nxMax,
      });
    });
  }

  function touchmove(e) {
    e.preventDefault();

    storePos(to, e);

    if (!rafPending) {
      rafPending = true;
      requestAnimationFrame(zoom);
    }
  }

  function startTouch(e) {
    e.preventDefault();

    rect = over.getBoundingClientRect();
    storePos(fr, e);

    oxRange = u.scales.x.max - u.scales.x.min;
    let left = fr.x;
    xVal = u.posToVal(left, 'x');

    document.addEventListener('touchmove', touchmove, { passive: true });
  }

  function endTouch() {
    document.removeEventListener('touchmove', touchmove);
  }

  function destroy() {
    over.removeEventListener('touchstart', startTouch);
    over.removeEventListener('touchend', endTouch);
  }

  return {
    hooks: {
      init,
      destroy,
    },
  };
}
