import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { PrivilegesService } from '../../../shared/services/privileges.service';
import { LayoutService } from '../../layout.service';
import { NgIf } from '@angular/common';
import { TreeControllerComponent } from '../../../pages/tree/tree-controller/tree-controller.component';
import { TreeNode } from '../../../pages/tree/tree-controller/tree-async/models/TreeNode';
import { TreeNodeService } from '../../../pages/tree/tree-controller/tree-async/TreeNodeService';
import { getNodeUrl } from '../../../pages/tree/tree-controller/tree-async/tree-node/getNodeUrl';

@Component({
  selector: 'app-main-layout-tree',
  templateUrl: './main-layout-tree.component.html',
  styleUrls: ['./main-layout-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, TreeControllerComponent],
})
export class MainLayoutTreeComponent {
  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private privilegesService: PrivilegesService,
    private layoutService: LayoutService,
  ) {}

  @ViewChild('treeList') treeList: TreeControllerComponent;

  @Output() nodeSelected = new EventEmitter<void>();

  private selectedItem: TreeNode;

  itemSelected(item: TreeNode) {
    // console.log('=>(main-layout-tree.component.ts:31) item', item);
    // console.log('main layout item selected -> item', item);

    //redirect to right place, skip if ith child of pointing url

    if (
      item &&
      item.isPreSetElement &&
      item.pointingUrl &&
      !this.router.url.includes(item.pointingUrl)
    ) {
      this.navigate(item.pointingUrl);
    }
    if (item) {
      this.navigate(getNodeUrl(item));
      if (item.is_end_type || item.id === 0) this.nodeSelected.emit();
    }

    //TODO redirect to dashboard etc

    this.selectedItem = item;
    this.layoutService.selectedItem = item;
    this.cdr.detectChanges();
    // if (this.isMobile()) this.openInspectModal();
  }

  setTreeNodeService(treeNodeService: TreeNodeService) {
    this.layoutService.treeNodeService = treeNodeService;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  itemUpdated(item: TreeNode) {
    // TODO remove whole function
    // console.debug(item);
    //this causes double item select at tree init
    // if (item.id === this.selectedItem?.id) {
    //   console.log('From item updated', item);
    //   this.itemSelected(item);
    // }
  }

  private navigate(url: string) {
    this.router.navigate([url]).catch(console.error);
  }

  displayTree() {
    return !!this.privilegesService.organization();
  }
}
