<div #uplotContainer class="uplot-container">
  <div class="header">
    @if (loaded) {
      <ng-content></ng-content>
      <ng-container>
        <div *ngIf="showSettings && getButtonsOpts() && uplot">
          <app-graph-buttons [opts]="getButtonsOpts()" [rangeInHours]="rangeInHours">
            <!--    <span>{{ getRangeString(currentRange) }}</span>-->
          </app-graph-buttons>
        </div>
        <div class="ranges">
          <app-scale-settings
            *ngIf="showScaleSettings"
            [currentScale$]="currentScale$"
            [rangeInHours]="rangeInHours"
          ></app-scale-settings>

          <app-range-picker
            [currentRange]="currentRange"
            (rangeHasChanged)="setUplotRange($event)"
          ></app-range-picker>
        </div>
      </ng-container>
    }
  </div>
</div>
