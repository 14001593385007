import { getUplotCursorOptions } from '../../graph/config/uplotCursorOptions';

export function heatmapHourlyCursor() {
  const cursor = getUplotCursorOptions(false);

  (<any>cursor).dataIdx = (uplot: any, seriesIdx: number) => {
    const x = uplot.cursor.left * devicePixelRatio + uplot.bbox.left;
    const y = uplot.cursor.top * devicePixelRatio + uplot.bbox.top;

    const hoveredElement = uplot?.heatmap?.items?.find((o) => {
      const xMatch = x >= o.x && x < o.x + o.width;
      const yMatch = y >= o.y && y < o.y + o.height;
      return xMatch && yMatch;
    });

    uplot.cursor.idx = hoveredElement ? hoveredElement.id : null;
    if (uplot.cursor._prev_idx != uplot.cursor.idx) uplot.redraw();
    uplot.cursor._prev_idx = uplot.cursor.idx;
    return seriesIdx; //series idx
  };

  (<any>cursor).bind = {
    mouseleave: (u) => {
      return () => {
        u.cursor.idx = null;
        u.redraw();
      };
    },
  };

  return cursor;
}
