<ng-container *ngIf="data">
  <section *ngIf="data?.values" class="structure-container">
    <div class="data-layout">
      <span>{{ 'DASHBOARD.DATA_LAYOUT' | translate }}</span>
      <div>
        <button
          class="layout"
          (click)="setLayout('column')"
          [class.active]="getLayout() === 'column'"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>

        <button
          class="layout right"
          (click)="setLayout('row')"
          [class.active]="getLayout() === 'row'"
        >
          <mat-icon>arrow_downward</mat-icon>
        </button>
      </div>
    </div>

    <p
      class="add-series"
      matTooltip="{{ !canAddValue() ? ('DASHBOARD.YOU_CANT_ADD_MORE_SERIES' | translate) : null }}"
    >
      <span>{{ 'DASHBOARD.DATA' | translate }}</span>
      <button (click)="addValue()" [disabled]="!canAddValue()">
        <mat-icon svgIcon="add_circle"></mat-icon>
        {{ 'DASHBOARD.ADD_NEW_SERIES' | translate }}
      </button>
    </p>

    <mat-tab-group [(selectedIndex)]="selectedTabId">
      <mat-tab [bodyClass]="'mat-tab-padding'" *ngFor="let series of data.values; let i = index">
        <ng-template mat-tab-label>
          <div class="label">
            <span>{{ i + 1 }}</span>
            <mat-icon (click)="deleteValue(i); $event.preventDefault(); $event.stopPropagation()"
              >cancel</mat-icon
            >
          </div>
        </ng-template>

        <app-dashboard-value-display-settings-input
          [data]="series"
        ></app-dashboard-value-display-settings-input>
      </mat-tab>
    </mat-tab-group>
  </section>
</ng-container>
