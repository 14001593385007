import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { RangePickerComponent } from '../../../../../shared/components/graph-uplot/range-picker/range-picker.component';
import { AsyncPipe, NgIf, TitleCasePipe } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardPieChartAggComponent } from './dashboard-pie-chart-agg.component';

@Component({
  selector: 'app-dashboard-pie-chart-weekly-agg',
  standalone: true,
  imports: [
    BaseChartDirective,
    RangePickerComponent,
    NgIf,
    AsyncPipe,
    MatProgressBar,
    TranslateModule,
  ],
  providers: [TitleCasePipe],
  templateUrl: './dashboard-pie-chart.component.html',
  styleUrl: './dashboard-pie-chart.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPieChartWeeklyAggComponent extends DashboardPieChartAggComponent {
  override getSeriesLength() {
    return 7;
  }

  override seriesIndexExtraction(date: Date): number {
    return date.getDay();
  }

  override getSeriesLabel(i: number) {
    const date = this.seriesDate[i as number];
    if (!date) return null;
    const day = this.capitalize.transform(
      date.toLocaleString(this.localeService.locale, {
        weekday: 'long',
        timeZone: this.localeService.timezone,
      }),
    );

    return `${day}`;
  }
}
