import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TreeNodeService } from '../TreeNodeService';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TreeFilterModalComponent } from './tree-filter-modal/tree-filter-modal.component';
import { TreeNodeRefreshOptions } from '../models/TreeNodeRefreshOptions';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleExpandComponent } from './toggle-expand/toggle-expand.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TreeSearchComponent } from './tree-search/tree-search.component';
import { UserGuideDirective } from '../../../../../shared/app-support/user-guide/user-guide.directive';
import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import breakpoints from '../../../../../shared/utils/breakpoints';

@Component({
  selector: 'app-tree-header-buttons',
  templateUrl: './tree-header-buttons.component.html',
  styleUrls: ['./tree-header-buttons.component.scss'],
  standalone: true,
  imports: [
    TreeSearchComponent,
    MatTooltipModule,
    MatIconModule,
    ToggleExpandComponent,
    TranslateModule,
    UserGuideDirective,
    AsyncPipe,
    NgIf,
    NgTemplateOutlet,
  ],
})
export class TreeHeaderButtonsComponent implements OnInit {
  protected mobileLayout: boolean;

  @Input() treeNodeService: TreeNodeService;

  constructor(
    private cd: ChangeDetectorRef,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe(breakpoints).subscribe({
      next: (breakpoint) => {
        this.mobileLayout = breakpoint.matches;
        this.cd.detectChanges();
      },
    });
  }

  openFilter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      treeNodeService: this.treeNodeService,
    };
    this.dialog
      .open(TreeFilterModalComponent, dialogConfig)
      .afterClosed()
      .subscribe(() => this.treeNodeService.refresh(TreeNodeRefreshOptions.VIEW));
  }

  refresh() {
    this.treeNodeService.refresh(TreeNodeRefreshOptions.ALL);
  }
}
