import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, Observable, merge, Subject, of } from 'rxjs';
import { LayoutService } from '../layout.service';
import { WaitService } from '../../shared/services/wait.service';
import { InvitationsComponent } from '../invitations/invitations.component';
import { MatDialog } from '@angular/material/dialog';
import { PrivilegesService } from '../../shared/services/privileges.service';
import {
  HandleQueryEvent,
  HandleQueryComponent,
} from '../../shared/components/handle-query/handle-query.component';
import { ActivatedRoute, Data, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { LoadingCircleComponent } from '../loading-circle/loading-circle.component';
import { FooterComponent } from '../footer/footer.component';
import { NoItemSelectedComponent } from './no-item-selected/no-item-selected.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MainLayoutTreeComponent } from './main-layout-tree/main-layout-tree.component';
import { HeaderComponent } from './header/header.component';
import { NgIf, AsyncPipe, NgClass } from '@angular/common';
import { MatSidenav, MatSidenavContainer, MatSidenavContent } from '@angular/material/sidenav';
import { MatIcon } from '@angular/material/icon';
import { BreakpointObserver } from '@angular/cdk/layout';
import breakpoints, { smallBreakpoints } from '../../shared/utils/breakpoints';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    HandleQueryComponent,
    HeaderComponent,
    MainLayoutTreeComponent,
    MatProgressBarModule,
    RouterOutlet,
    NoItemSelectedComponent,
    FooterComponent,
    LoadingCircleComponent,
    AsyncPipe,
    MatSidenavContainer,
    MatSidenavContent,
    MatSidenav,
    MatIcon,
    NgClass,
  ],
})
export class MainLayoutComponent implements OnInit, OnDestroy {
  protected sidenavOpened: boolean = true;

  protected mobileLayout: boolean;

  protected smallLayout: boolean;

  unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public layoutService: LayoutService,
    private waitService: WaitService,
    private dialog: MatDialog,
    private privilegesService: PrivilegesService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.breakpointObserver.observe(breakpoints).subscribe((breakpoint) => {
      this.mobileLayout = breakpoint.matches;
      if (!this.mobileLayout) this.sidenavOpened = true;
    });
    this.breakpointObserver.observe(smallBreakpoints).subscribe((breakpoint) => {
      this.smallLayout = breakpoint.matches;
    });
  }

  itemSelected$ = this.layoutService.selectedItem$.pipe();

  get displayRouter() {
    return this.layoutService.organizationLoaded();
  }

  get displayPage(): boolean {
    return this.layoutService.languageSet;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  isLoading() {
    return this.waitService.isLoading$;
  }

  onInvitation(event: HandleQueryEvent) {
    this.dialog.open(InvitationsComponent, { data: { code: event.value } });
  }

  $routeData: Observable<null | Data> = merge(
    this.layoutService.organizationChanged$,
    of(null),
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
  ).pipe(
    map(() => {
      let child = this.route.firstChild;
      while (child) {
        if (child.firstChild) child = child.firstChild;
        else if (child.snapshot?.data) {
          return child.snapshot.data;
        } else return null;
      }
      return null;
    }),
  );

  requireSelectedItem$ = this.$routeData.pipe(
    map((data: any) => {
      if (!this.privilegesService.organization()) return false;
      if (!data) return true;
      return data?.selectedItemRequired !== false;
    }),
  );

  showTree$ = this.$routeData.pipe(
    map((data: any) => {
      if (!this.privilegesService.organization()) return false;
      if (!data) return true;
      return data?.treeDisplay !== false;
    }),
  );

  toggleSidenav() {
    if (!this.mobileLayout) return;
    this.sidenavOpened = !this.sidenavOpened;
  }

  closeSidenav() {
    if (!this.mobileLayout) return;
    this.sidenavOpened = false;
  }
}
