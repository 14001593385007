import { AfterViewInit, Component } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { BaseChartDirective } from 'ng2-charts';
import { MatProgressBar } from '@angular/material/progress-bar';
import { RangePickerComponent } from '../../../../../shared/components/graph-uplot/range-picker/range-picker.component';
import { TranslateModule } from '@ngx-translate/core';
import { DashboardHeatmapComponent } from './dashboard-heatmap.component';
import { HeatMapDailyComponent } from '../../../../../shared/components/graph-uplot/heat-map/daily/heat-map-daily.component';

@Component({
  selector: 'app-dashboard-heatmap-daily',
  standalone: true,
  imports: [
    AsyncPipe,
    BaseChartDirective,
    MatProgressBar,
    NgIf,
    RangePickerComponent,
    TranslateModule,
    HeatMapDailyComponent,
  ],
  templateUrl: './dashboard-heatmap-daily.component.html',
  styleUrl: './dashboard-heatmap.component.scss',
})
export class DashboardHeatmapDailyComponent
  extends DashboardHeatmapComponent
  implements AfterViewInit {}
