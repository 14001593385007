import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { DashboardItem } from '../models/DashboardItem';
import { Subject, takeUntil } from 'rxjs';
import { DashboardService } from '../services/dashboard.service';

@Component({
  selector: 'app-base-widget',
  template: '',
  standalone: true,
})
export class BaseWidgetComponent implements OnDestroy, AfterViewInit {
  protected unsubscribe = new Subject<void>();

  @Input() item: DashboardItem;

  protected detach = true;

  constructor(
    protected dashboardService: DashboardService,
    protected cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit() {
    if (this.detach) this.detachComponent();
  }

  detachComponent() {
    this.dashboardService.selectedItem$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((item) => this.changeDetection(item === this.item));
    this.dashboardService.optionsChanged$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.detectChanges());
  }

  get data(): any {
    return this.item.data;
  }

  protected detectChanges(): void {
    this.cd.detectChanges();
  }

  private changeDetection(value: boolean) {
    if (value === true) this.cd.reattach();
    else this.cd.detach();
  }

  protected itemLoaded() {
    this.dashboardService.itemLoaded(this.item);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
