import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Host,
  Input,
  Optional,
  Output,
  SkipSelf,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-emu-list-input',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    TranslateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatProgressBarModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: EmuListInputComponent,
      multi: true,
    },
  ],
  templateUrl: './emu-list-input.component.html',
  styleUrl: './emu-list-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class EmuListInputComponent implements AfterViewInit, ControlValueAccessor {
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional()
    @Host()
    @SkipSelf()
    private controlContainer: ControlContainer,
  ) {}

  @Input() name: string;

  @Input() placeholder: string;

  // @Input({ required: true }) value: any;
  @Input() value: any;

  @Input({ required: true }) filteredOptions$: Observable<any>;

  @Output() valueChange = new EventEmitter<any>();

  @Output() changes = new EventEmitter<string>();

  @Output() optionSelected = new EventEmitter<any>();

  @Output() optionCleared = new EventEmitter<any>();

  @Output() listOpened = new EventEmitter<void>();

  @Output() listClosed = new EventEmitter<void>();

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() valueFnc: (value: any) => any; //mat option value at select

  clearButtonAvailable = false;

  @Input() displayWith: any; // autocomplete display with

  @Input() autocomplete: boolean = true;

  @Input() clearButtonHidden: boolean = false;

  @Input() loading$: Observable<boolean>;

  @Input() className: string;

  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  @ContentChild('displayedValue') displayedValueTemplateRef: TemplateRef<any>;

  private unsubscribe$ = takeUntilDestroyed();

  private endInterval$ = new Subject<void>();

  isValid = true;

  @Input() formControlName: string;

  @Input() validator: (value: string) => boolean;

  //control can be provided instead of formControlName
  @Input() control: AbstractControl;

  private onChangeFormControl: Function;

  private onBlurFormControl: Function;

  protected focusedOut: boolean = false;

  closePanel() {
    this.autocompleteTrigger.closePanel();
  }

  ngAfterViewInit() {
    if (this.controlContainer && this.formControlName && !this.control)
      this.control = this.controlContainer.control.get(this.formControlName);

    timer(0, 300)
      .pipe(this.unsubscribe$, takeUntil(this.endInterval$))
      .subscribe(() => {
        this.updateClearButton();
        if (this.clearButtonAvailable == true) this.stopInterval();
      });
  }

  updateValue(value: any) {
    // console.log('=>(emu-list-input.component.ts:142) value', value);
    if (this.disabled || this.readonly) return;
    this.stopInterval();
    this.valueChange.emit(value);
    this.changes.emit(value);
    if (this.onChangeFormControl) this.onChangeFormControl(value);
    this.updateClearButton();
    this.validate();
  }

  private stopInterval() {
    // this.endInterval$.next();
  }

  validate() {
    const validatorValue = this.validator ? this.validator(this.value) : true;
    this.isValid = validatorValue && this.isFromControlValid();
    this.cdr.detectChanges();
  }

  clear() {
    this.value = '';
    this.updateValue(this.value);
    this.optionSelected.emit(null);
    this.optionCleared.emit(null);
    this.cdr.detectChanges();
  }

  private updateClearButton() {
    // console.log('-> this.value', this.value);
    this.clearButtonAvailable =
      this.value && (this.value.length > 0 || typeof this.value === 'object');
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChangeFormControl = fn;
  }

  registerOnTouched(fn: any): void {
    this.onBlurFormControl = fn;
  }

  writeValue(value: any): void {
    // if (value && typeof value !== 'string') {
    //   console.log('value: ', value);
    //   throw new Error('THIS COMPONENT HANDLES STRING ONLY');
    // }
    this.value = value;
    this.updateValue(value);
    // console.log('=>(emu-input.component.ts:174) obj', obj);
  }

  isFromControlValid() {
    if (!this.control) return true;
    return this.control.valid;
  }

  isTouched(): boolean {
    return this.control ? this.control.touched : this.focusedOut;
  }

  focusOut(): void {
    if (this.onBlurFormControl && typeof this.onBlurFormControl == 'function')
      this.onBlurFormControl();
    this.focusedOut = true;
  }
}
