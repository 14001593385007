import { Component } from '@angular/core';
import { map, take } from 'rxjs';
import { DashboardService } from '../../services/dashboard.service';
import { LayoutService } from '../../../../layout/layout.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { DisabledDirective } from '../../../../shared/directives/disabled.directive';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { WaitService } from '../../../../shared/services/wait.service';
import domtoimage from 'dom-to-image';
import { DashboardTemplateService } from '../../services/dashboard-template.service';
import { AlertService } from '../../../../shared/services/alert.service';
import { EmuListInputComponent } from '../../../../shared/components/inputs/emu-list-input/emu-list-input.component';
import { TimestampRelativeComponent } from '../../../../shared/components/timestamp-relative/timestamp-relative.component';
import { DashboardTemplatesSelectComponent } from './dashboard-templates-select/dashboard-templates-select.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DashboardDto } from '../../../../../api-main';
import { DashboardTemplateModalComponent } from './dashboard-template-modal/dashboard-template-modal.component';
import { PrivilegesService } from '../../../../shared/services/privileges.service';
@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  standalone: true,
  imports: [
    MatButtonModule,
    MatIconModule,
    DisabledDirective,
    AsyncPipe,
    TranslateModule,
    NgIf,
    EmuListInputComponent,
    TimestampRelativeComponent,
    DashboardTemplatesSelectComponent,
  ],
})
export class DashboardHeaderComponent {
  get editMode(): boolean {
    return this.dashboardService.editMode$.value;
  }

  get downloadingPng(): boolean {
    return this.dashboardService.dashboardIsDownloading();
  }

  get allowEdit(): boolean {
    return this.privilegesService.hasEditPrivileges(this.layoutService.selectedItem);
  }

  toggleEditMode(value: boolean = null) {
    if (value != null) this.dashboardService.editMode$.next(value);
    else this.dashboardService.editMode$.next(!this.editMode);
  }

  get changesMade() {
    return this.dashboardService.dashboardItemChanged;
  }

  constructor(
    private dashboardService: DashboardService,
    private dashboardTemplateService: DashboardTemplateService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private alertService: AlertService,
    private layoutService: LayoutService,
    private waitService: WaitService,
    private privilegesService: PrivilegesService,
  ) {}

  dashboardName$ = this.layoutService.selectedItem$?.pipe(
    map((selectedItem) => {
      return selectedItem?.item_name ?? '';
    }),
  );

  restore() {
    if (this.changesMade)
      this.dashboardService.restoreDashboard().subscribe(() => {
        this.dashboardService.editMode$.next(false);
      });
    else this.dashboardService.editMode$.next(false);
  }

  download() {
    //TODO download will have to be done on server
    // TODO handle changing dashboard during download (stop download?)
    this.dashboardService.dashboardIsDownloading.set(true);
    this.waitService.start();
    const container = <HTMLElement>document.querySelector('.dashboard-container main');

    const itemTop = Array.from(container.querySelectorAll('gridster-item'))
      .map((item) => (item ? item.getBoundingClientRect() : null))
      .map((o) => {
        if (!o) return null;
        return o.top + o.height;
      })
      .filter((o) => !!o)
      .sort((a, b) => a - b);

    const height = Math.max(...itemTop);

    container.style.height = height + 'px';

    domtoimage
      .toPng(container)
      .then((value: any) => {
        const itemName = this.layoutService.selectedItem.item_name ?? 'dashboard';
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = value;
        a.download = itemName;
        a.click();
        container.style.height = '';
      })
      .catch((error: any) => {
        console.log('Failed to generate dashboard', error);
      })
      .finally(() => {
        this.waitService.stop();
        this.dashboardService.dashboardIsDownloading.set(false);
      });
  }

  showTemplateSelect() {
    return !this.showSaveAsTemplate() && !this.dashboardService.editMode$.value;
  }

  showSaveAsTemplate() {
    return this.dashboardService.items?.length > 0;
  }

  private saveAsTemplate(dashboard: DashboardDto = null) {
    this.waitService.start();
    this.dashboardTemplateService.save(dashboard).subscribe({
      next: () => this.alertService.info(this.translate.instant('DASHBOARD.TEMPLATE_SAVED')),
      error: () => this.alertService.error(this.translate.instant('TEMPLATE.SELECT_ERROR')),
      complete: () => {
        this.waitService.stop();
      },
    });
  }

  openSaveTemplateModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      ...this.dashboardService.dashboard,
      name: this.layoutService.selectedItem.item_name,
    } as DashboardDto;
    // eslint-disable-next-line security/detect-non-literal-fs-filename
    const dialogRef = this.dialog.open(DashboardTemplateModalComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((data: any) => {
        if (!data) return;
        // console.log('-> data', data);
        this.saveAsTemplate(data);
      });
  }
}
