/**
 * EMU Invoice API
 * EMU Invoice API documentation<br> Do not use 403 status, use 409 instead<br>
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CustomerDto } from './customerDto';
import { InvoiceItemDto } from './invoiceItemDto';

export interface InvoiceDto {
  /**
   * Who create item - user identifier. Not used during save.
   */
  readonly created_by?: string;
  /**
   * Creation date
   */
  readonly created_at?: string;
  /**
   * Who modify item - user identifier. Not used during save.
   */
  readonly modified_by?: string;
  /**
   * Modification date
   */
  readonly modified_at?: string;
  /**
   * id
   */
  readonly id?: number;
  /**
   * id
   */
  readonly invoice_number?: string;
  /**
   * customer data
   */
  customer?: CustomerDto;
  /**
   * customer id
   */
  customer_id: number;
  /**
   * template id
   */
  template_id: number;
  /**
   * organization id
   */
  organization_id: number;
  /**
   * invoice items
   */
  items: Array<InvoiceItemDto>;
  /**
   * Sale date
   */
  sale_date: string;
  /**
   * Sale date
   */
  due_date: string;
  /**
   * value
   */
  gross_sum?: number;
  /**
   * value
   */
  net_sum?: number;
}
