import { Injectable } from '@angular/core';
import { DashboardDto, DbDefaultResponse } from '../../../../api-main';
import { map, Observable } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { LayoutService } from '../../../layout/layout.service';
import { DashboardItemType } from '../models/DashboardItemType.model';
import { DashboardItem } from '../models/DashboardItem';
import { DashboardTemplateApiService } from './dashboard-template-api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardTemplateService {
  constructor(
    private dashboardService: DashboardService,
    private dashboardTemplateService: DashboardTemplateApiService,
    private layoutService: LayoutService,
  ) {}

  save(dashboard: DashboardDto = null): Observable<DbDefaultResponse> {
    if (!dashboard) dashboard = this.dashboardService.dashboard;

    dashboard = JSON.parse(JSON.stringify(dashboard));
    if (!dashboard.name || dashboard.name.length == 0)
      dashboard.name = this.layoutService.selectedItem.item_name;
    dashboard.config.forEach((item) => {
      // item.data = {};
      this.clearItems(item);
    });

    dashboard.id = 0;

    return this.dashboardTemplateService.save(dashboard);
  }

  private clearItems(item: DashboardItem) {
    switch (item.type) {
      case DashboardItemType.GRAPH:
        item.data.graph.series.forEach((serie) => {
          serie.loggerId = null;
          serie.deviceId = null;
          serie.valueId = null;
        });
        break;
      case DashboardItemType.VALUE_DISPLAY:
        item.data.valueDisplay.loggerId = null;
        item.data.valueDisplay.deviceId = null;
        item.data.valueDisplay.valueId = null;
        break;
      case DashboardItemType.MULTI_VALUE_DISPLAY:
        item.data.multipleValueDisplay.values.forEach((o) => {
          o.loggerId = null;
          o.deviceId = null;
          o.valueId = null;
        });
        return;
    }
  }

  list(): Observable<DashboardDto[]> {
    return this.dashboardTemplateService.list().pipe(map((o) => o.data));
  }

  get(id: number): Observable<DashboardDto> {
    return this.dashboardTemplateService.get(id);
  }

  delete(id: number): Observable<DbDefaultResponse> {
    return this.dashboardTemplateService.delete(id);
  }
}
