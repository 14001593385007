import { Injectable } from '@angular/core';
import { TreeNode } from './models/TreeNode';

// export enum PresetTreeElementsId {
//   loggers,
//   policies,
//   reports,
//   policies_permissions,
// }

@Injectable({
  providedIn: 'root',
})
export class PresetTreeElementsService {
  // constructor(
  //   private loggerService: LoggerService,
  //   private gpService: GpService,
  //   private layoutService: LayoutService,
  // ) {}

  readonly rootId = 0;

  // private refreshPresetElements$ = new Subject<PresetTreeElementsId[]>();
  //
  // private debounce = 100;

  private presetTreeElements: TreeNode[] = [
    {
      item_name: 'NAVBAR.LOGGER_LIST',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'loggers',
      parent_id: this.rootId,
      icon: 'logger',
      pointingUrl: '/logger',
    },
    {
      item_name: 'NAVBAR.GROUP_POLICIES',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'policies',
      parent_id: this.rootId,
      icon: 'policy',
      pointingUrl: '/tree/gp',
      always_available: true,
    },
    {
      item_name: 'NAVBAR.REPORTS',
      is_authorized: true,
      is_end_type: true,
      isPreSetElement: true,
      id: 'reports',
      parent_id: this.rootId,
      icon: 'document',
      pointingUrl: '/reports',
      always_available: true,
    },
    {
      item_name: 'NAVBAR.GIVEN_PERMISSIONS',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'policies-permissions',
      parent_id: this.rootId,
      icon: 'user_policy',
      pointingUrl: '/tree/gp/permissions',
    },
    {
      item_name: 'NAVBAR.CUSTOMERS',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'customer',
      parent_id: this.rootId,
      icon: 'user',
      pointingUrl: '/organization/customer',
    },
    {
      item_name: 'NAVBAR.INVOICES',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'invoice',
      parent_id: this.rootId,
      icon: 'invoice',
      pointingUrl: '/invoice',
    },
    {
      item_name: 'NAVBAR.INVOICES_TEMPLATES',
      is_authorized: true,
      is_end_type: true,
      // is_end_type: false,
      isPreSetElement: true,
      id: 'invoice-templates',
      parent_id: this.rootId,
      icon: 'invoice',
      pointingUrl: '/invoice/templates',
    },
  ];

  getPresetElements(): TreeNode[] {
    return this.presetTreeElements;
  }
}
