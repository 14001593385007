<div class="graph-container">

  <div class="header">
    <span class="name">{{ data.name ?? ('DASHBOARD.TYPE_GRAPH' | translate) }}</span>
    <app-range-picker [currentRange]="range" (rangeHasChanged)="changeRange($event)">
    </app-range-picker>
  </div>

  <div class="graph" *ngIf="range">
    @if (!noData) {
      <app-heat-map-daily
        (rangeChanged)="changeRange($event, true)"
        [displayedRange]="range"
        [data]="getData"
        [refresh$]="graphRefresh$"
        [seriesConfig$]="seriesConfig$"
        (graphLoaded)="itemLoaded()"
      ></app-heat-map-daily>
    } @else {
      <p class="no-data">{{ 'INSPECT.NO_DATA' | translate }}</p>
    }
  </div>
</div>
